<!-- eslint-disable vue/no-v-html -->
<template>
	<w-flex shrink>
		<w-btn-delete flat mini small @click="dialog = true">{{ $t('customer.administration.delete_customer') }}</w-btn-delete>
		<w-dialog v-model="dialog" error max-width="500" :title="$t('customer.administration.delete_customer')">
			<w-flex v-t="'customer.administration.delete_customer_dialog.first'" my-2 />
			<w-flex v-t="'customer.administration.delete_customer_dialog.second'" my-2 />
			<w-flex v-t="'customer.administration.delete_customer_dialog.third'" my-2 />
			<w-flex my-2 v-html="$t('customer.administration.delete_customer_dialog.fourth', { name: value.company })" />
			<w-flex my-2>
				<v-text-field v-model="confirmationName" color="error" @keydown.enter="deleteVendor()" @paste.prevent />
			</w-flex>
			<template v-slot:actions>
				<w-btn color="error" class="ml-auto mr-0" :disabled="!isNameConfirmed" flat :loading="loading" @click="deleteVendor()">
					{{ $t('customer.administration.delete_customer') }}
				</w-btn>
			</template>
		</w-dialog>
	</w-flex>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'VendorDeletor',
	mixins: [CustomersManagerModuleGuard],
	props: {
		value: {
			required: true,
			type: Object,
			validator: propValue => {
				return propValue.deleted_at && propValue.id
			}
		}
	},
	data: function () {
		return {
			confirmationName: '',
			dialog: false,
			loading: false
		}
	},
	computed: {
		isNameConfirmed: function () {
			return this.confirmationName === this.value.company
		}
	},
	watch: {
		dialog: {
			handler: 'onDialogStateChanged'
		}
	},
	methods: {
		/**
		 * @return {Promise<void>}
		 */
		deleteVendor: function () {
			this.loading = true
			this.service
				.deleteVendor(this.value)
				.then(() => {
					this.dialog = false
				})
				.finally(() => {
					this.loading = false
				})
		},
		/**
		 * @param {boolean} isOpen
		 */
		onDialogStateChanged: function (isOpen) {
			return new Promise(resolve => {
				if (!isOpen) {
					this.confirmationName = ''
				}

				resolve()
			})
		}
	}
}
</script>
